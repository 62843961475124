import './App.scss';
import Header from './Components/Header';
import FooterComp from './Components/Footer';
import { useState, useEffect } from 'react';

const eventMethod = window.addEventListener
  ? 'addEventListener'
  : 'attachEvent';
const eventer = window[eventMethod];
const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

const src = 'https://ipage.cognni.ai';

function App() {
  const [scrollSizeApp, setScrollApp] = useState();
  const [scrollDiv, setScrollDiv] = useState({
    scrolled: false,
    scrollHeight: null,
  });
  const [pageId, setPageId] = useState(3334);

  const pageIdHandler = (hostname) => {
    if (hostname === 'www.ipages.ai') {
      setPageId(1);
    }
    if (hostname === 'solutions.cognni.ai') {
      setPageId(2);
    }
    if (hostname === 'lp1.cognni.ai') {
      setPageId(3);
    }
    if (hostname === 'lp2.cognni.ai') {
      setPageId(4);
    }
    if (hostname === 'lp3.cognni.ai') {
      setPageId(5);
    }
    // 3 steps embedded
    if (hostname === 'howto.cognni.ai') {
      setPageId(333);
    }
    if (hostname === 'usecases.cognni.ai') {
      setPageId(3334);
    }
    if (hostname === '123solution.co') {
      setPageId(6);
    }
    if (hostname === 'kizan.inforisk.com') {
      setPageId(7);
    }
    if (hostname === 'sw1.inforisk.com') {
      setPageId(8);
    }
    if (hostname === 'inforisk.com') {
      setPageId(9);
    }
    if(hostname === 'inforisk.cognni.ai') {
      setPageId(999);
    }
  };

  useEffect(() => {
    // console.log(window.location.hostname);
    // Handle template page
    pageIdHandler(window.location.hostname);
    // Receive messages from child
    eventer(messageEvent, function (e) {
      if (e.origin === src) {
        setScrollApp(e.data?.height);

        if (e.data?.scroll !== null && !scrollDiv.scrolled) {
          setScrollDiv({
            scrolled: true,
            scrollHeight: e.data?.scroll,
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, scrollDiv.scrollHeight);
    }, 500);
  }, [scrollDiv.scrolled]);

  return (
    <div className='wrapper'>
      <Header />
      <iframe
        src={src + '/?id=' + pageId}
        height={scrollSizeApp || 700}
        width='100%'
        scrolling='no'
        title='app'
      ></iframe>
      <FooterComp />
    </div>
  );
}

export default App;
